"use client";

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Autoplay } from "swiper/modules";
import { FaStar } from "react-icons/fa";
import "swiper/css";

const StarRating: React.FC<{ rating: number }> = ({ rating }) => (
  <div 
    className="flex justify-center" 
    role="img" // Added role="img"
    aria-label={`Rated ${rating} out of 5 stars`}
  >
    {Array.from({ length: 5 }, (_, idx) => (
      <FaStar
        key={idx}
        className={`h-5 w-5 ${idx < rating ? "text-yellow-400" : "text-slate-300"}`}
        aria-hidden="true"
      />
    ))}
  </div>
);

const Testimonials: React.FC = () => {
  const testimonials = [
    {
      quote:
        "The Relationship Counselling services provided by Alberta Counselling Services in Calgary have been life-changing. Murray's expertise and dedication are unparalleled, making a significant difference in my personal growth.",
      name: "E. M.",
      rating: 5,
    },
    {
      quote:
        "Looking for the Best Psychologist in Calgary? Murray at Alberta Counselling Services is your answer. His compassionate approach and expertise helped me overcome my challenges.",
      name: "S. A.",
      rating: 5,
    },
    {
      quote:
        "The Registered Psychologist services offered by Alberta Counselling Services exceeded my expectations. Murray provided me with tools that transformed my mental health.",
      name: "L. C.",
      rating: 5,
    },
    {
      quote:
        "Through Couples Counselling in Calgary, Murray helped us rebuild our trust and communication. His practical guidance was truly transformative for our relationship.",
      name: "V. M.",
      rating: 5,
    },
    {
      quote:
        "Family Counselling at Alberta Counselling Services brought our family closer together. Murray's approach resolved long-standing conflicts and improved our dynamics.",
      name: "A. A.",
      rating: 5,
    },
    {
      quote:
        "If you're seeking Calgary Therapy Services, Alberta Counselling Services is the best choice. Murray provided a supportive space to work through my struggles.",
      name: "J. A.",
      rating: 5,
    },
    {
      quote:
        "Marriage Counsellor services offered by Murray saved our marriage. His actionable advice and empathetic approach made a world of difference.",
      name: "G. D.",
      rating: 5,
    },
    {
      quote:
        "Marriage Counselling gave us the tools to reconnect and move forward as a stronger couple. Thank you, Murray!",
      name: "J. M.",
      rating: 5,
    },
    {
      quote:
        "Murray has provided me with clarity and peace of mind. His skill and care are exceptional.",
      name: "N. L.",
      rating: 5,
    },
    {
      quote:
        "He helped us develop better communication and understanding. Murray's expertise is truly remarkable.",
      name: "P. S.",
      rating: 5,
    },
    {
      quote:
        "If you're looking for the Best Psychologist in Calgary, look no further. Murray's thoughtful and effective techniques have made a significant impact on my life.",
      name: "E. T.",
      rating: 5,
    },
    {
      quote:
        "They provided me with the tools to improve my mental health and overall outlook on life. Highly recommend Murray's guidance.",
      name: "K. L.",
      rating: 5,
    },
    {
      quote:
        "Alberta Counselling Services brought us closer and gave us skills to handle future challenges. Murray's support was exceptional.",
      name: "S. F.",
      rating: 5,
    },
    {
      quote:
        "Murray has transformed how we interact as a family. His support and strategies were invaluable, and we are so grateful.",
      name: "Y. H.",
      rating: 5,
    },
  ];

  const swiperSettings = {
    modules: [Keyboard, Autoplay],
    spaceBetween: 30,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  };

  return (
    <section
      id="testimonials"
      className="py-4 sm:py-6 lg:py-8"
      aria-labelledby="testimonials-heading"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-0">
        <h2
          id="testimonials-heading"
          className="text-3xl font-bold text-center text-slate-900 sm:text-4xl lg:text-5xl mb-8"
        >
          Client Success Stories
        </h2>

        <div className="mt-8 lg:mt-12">
          <Swiper {...swiperSettings}>
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <article
                  className="h-full flex flex-col bg-white rounded-2xl p-6 lg:p-8 transition-all duration-300 border-2 border-transparent hover:border-emerald-500"
                  aria-label={`Testimonial from ${testimonial.name}`}
                >
                  <div className="flex-1">
                    <StarRating rating={testimonial.rating} />
                    <blockquote className="mt-6">
                      <p className="text-lg text-slate-700 leading-relaxed italic">
                        &ldquo;{testimonial.quote}&rdquo;
                      </p>
                    </blockquote>
                  </div>
                  <footer className="mt-8">
                    <p className="text-base font-semibold text-slate-900">
                      {testimonial.name}
                    </p>
                  </footer>
                </article>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
